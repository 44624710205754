import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const AboutPageTemplate = ({ title, content, contentComponent, featuredimage }) => {
  const PageContent = contentComponent || Content

  return (
    <div className="content">	
      <div	
        className="full-width-image-container margin-top-0"	
        style={{	
          backgroundPosition: `center center`,
          backgroundAttachment: `fixed`,
          backgroundImage: `url(${	
            !!featuredimage?.childImageSharp ? featuredimage.childImageSharp.fluid.src : featuredimage	
          })`,	
        }}	
      >
         <h1	
            className="has-text-weight-bold is-size-1"	
            style={{	
              boxShadow: '0.5rem 0 0 #076950, -0.5rem 0 0 #076950',	
              backgroundColor: '#076950',	
              color: 'white',	
              padding: '1rem',	
            }}	
          >	
           {title}
          </h1>	
        </div>	
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  featuredImage: PropTypes.object
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        featuredimage={post.frontmatter.featuredimage}	
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        featuredimage {	
          childImageSharp {	
            fluid(maxWidth: 1920, quality: 100) {	
              ...GatsbyImageSharpFluid	
            }	
          }	
        }	
      }
    }
  }
`
